<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
part.section.general : "ข้อมูลทั่วไป"
part.section.price : "ข้อมูลราคา"
part.section.warranty : "ข้อมูลการรับประกัน"
part.section.others : "อื่น ๆ"

part.field.has_serial_no.yes : "ต้องระบุ"
part.field.has_serial_no.no : "ไม่จำเป็น"
part.field.strict_use.yes : "ต้องตรงรุ่นของ Drone"
part.field.strict_use.no : "ไม่จำเป็น"

part.field.warranty_duration.no : "ไม่มี"
part.field.repair_duration.no : "ไม่ระบุ"

part.field.additional_warranty.flight : "ชั่วโมงบิน {value} ชั่วโมง"
part.field.additional_warranty.charge : "การชาร์ต {value} รอบ"
part.field.additional_warranty.none : "ไม่มี"
</i18n>

<template>
	<div class="page-padding">
		<div class="content-with-details">
			<div class="content-header">{{$t('part.section.general')}}</div>
			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.sku')}}
				</div>
				<div class="content-value">
					{{part.sku}}
				</div>
			</div>

			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.name')}}
				</div>
				<div class="content-value">
					{{part.name | emptyCheck}}
				</div>
			</div>

			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.name_en')}}
				</div>
				<div class="content-value">
					{{part.name_en}}
				</div>
			</div>

			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.part_type')}}
				</div>
				<div class="content-value">
					{{$tenum('part_type',part.part_type)}}
				</div>
			</div>

			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.has_serial_no')}}
				</div>
				<div class="content-value">
					<span v-if="part.has_serial_no" class="text-warning text-bold">
						<a-icon type="warning" class="text-warning" />
						{{$t('part.field.has_serial_no.yes')}}
					</span>
					<span v-else>
						{{$t('part.field.has_serial_no.no')}}
					</span>
				</div>
			</div>

			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.strict_use')}}
				</div>
				<div class="content-value">
					<span v-if="part.strict_use" class="text-warning text-bold">
						<a-icon type="warning" class="text-warning"  />
						{{$t('part.field.strict_use.yes')}}
					</span>
					<span v-else>
						{{$t('part.field.strict_use.no')}}
					</span>
				</div>
			</div>
		</div>

		<div class="content-with-details">
			<div class="content-header">
				{{$t('part.section.price')}}
			</div>
			<div v-if="canViewImportant" class="content-row">
				<div class="content-label">
					{{$t('part.field.cost_per_unit')}}
				</div>
				<div class="content-value">
					{{part.cost_per_unit | price}}
				</div>
			</div>

			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.price_per_unit')}}
				</div>
				<div class="content-value">
					{{part.price_per_unit | price}}
				</div>
			</div>

		</div>


		<div class="content-with-details">
			<div class="content-header">
				{{$t('part.section.warranty')}}
			</div>

			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.warranty_duration')}}
				</div>
				<div class="content-value">
					{{displayWarrantyDuration}}
				</div>
			</div>
			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.additional_warranty')}}
				</div>
				<div class="content-value">
					{{displayAdditionalWarranty}}
				</div>
			</div>
			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.repair.duration')}}
				</div>
				<div class="content-value">
					{{displayRepairDuration}}
				</div>
			</div>
		</div>

		<div class="content-with-details">
			<div class="content-header">
				{{$t('part.section.others')}}
			</div>

			<!-- <div class="content-row">
				<div class="content-label">
					{{$t('part.field.remark')}}
				</div>
				<div v-trim class="content-value multiline">
					{{part.remark | emptyCheck}}
				</div>
			</div> -->

			<div class="content-row">
				<div class="content-label">
					{{$t('part.field.data_date')}}
				</div>
				<div class="content-value">
					{{part.data_date | date}}
				</div>
			</div>

			<div class="content-row">
				<div class="content-label">
					{{$t('common.field.updated_datetime')}}
				</div>
				<div class="content-value">
					<DayFromNowText :value="part.updated_datetime"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DayFromNowText from "@components/common/DayFromNowText.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
export default {
	components : {
		DayFromNowText,
	} ,
	mixins : [LoginInfoMixin] ,
	props : {
		part : {
			type: Object ,
			default : () => []
		}
	} ,
	computed : {
		canViewImportant() {
			return this.$isAdmin()
		} ,

		displayWarrantyDuration() {
			if (this.part && this.part.warranty_duration > 0) {
				return this.part.warranty_duration + " "+this.$t('common.day')
			} else {
				return this.$t('part.field.warranty_duration.no')
			}
		} ,
		displayAdditionalWarranty() {
			if (this.part && this.part.warranty_type && this.part.warranty_value > 0) {
				switch(this.part.warranty_type) {
					case "flight" :
						return this.$t('part.field.additional_warranty.flight',{'value' : this.part.warranty_value});
					case "charge" :
						return this.$t('part.field.additional_warranty.charge',{'value' : this.part.warranty_value});
				}
			}
			return this.$t('part.field.additional_warranty.none')
		} ,
		displayRepairDuration() {
			return this.part.remark ? this.part.remark + " " + this.$t('common.time.minute') : this.$t('part.field.repair_duration.no')
		} ,

	}
}
</script>
